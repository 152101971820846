<script setup>
import { useBible } from "~/composables/useBible";

const props = defineProps(["singleBible"]);
const emit = defineEmits(["select"]);

const { biblesColl } = useBible();

const isSelected = (bible) =>
  props.singleBible?.location.value?.bibleModel?.bibleCode == bible?.bibleCode;

function handleSelectBible({ bibleCode }) {
  useEvent("singlebible.selectBible", {
    singleBible: props.singleBible,
    bibleCode,
  });
}
</script>

<template>
  <v-menu origins="top right">
    <template #activator="{ on }">
      <v-btn class="select-button" @click="on()">
        {{
          singleBible?.location.value?.bibleModel?.bibleShortName ||
          transl("Select translation")
        }}
        <img class="chevron" src="/icons/chevron-down.svg" alt="" />
      </v-btn>
    </template>

    <template #default="{ close }">
      <div class="select-content">
        <div
          v-for="bibleModel in biblesColl"
          @click="
            handleSelectBible(bibleModel);
            close();
          "
          class="bible-transl"
          :class="{ active: isSelected(bibleModel) }"
        >
          <div class="d-flex">
            <div class="bible-shortname">{{ bibleModel?.bibleShortName }}</div>
            <div class="ml-2 strong-label">
              {{ bibleModel?.bibleLocale }}
            </div>
            <div v-if="bibleModel.bibleStrong" class="ml-2 strong-label">
              strong
            </div>
          </div>
          <div>{{ bibleModel?.bibleName }}</div>
        </div>
      </div>
    </template>
  </v-menu>
</template>

<style scoped>
.chevron {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  right: 7px;
  top: 0;
  top: 50%;
  transform: translate(-50%, -50%);
}

.select-button {
  border-radius: 6px;
  background-color: var(--root-white);
  width: 100%;
  color: black;
  padding: 0px 4px 0px 8px;
  justify-content: flex-start;
  box-shadow: 0px 4px 11px 0px #e1e5f1;
  height: 40px;
  align-items: flex-start;
  align-items: center;
  box-sizing: border-box;
}

.select-button {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  display: inline-block;
  padding-right: calc(10% + 12px);
  position: relative;
  line-height: 40px;
}
.more {
  position: absolute;
  top: 9px;
  right: 3px;
}
.select-content {
  max-width: 380px;
}
.icon {
  min-height: 24px;
  min-width: 24px;
  align-items: flex-start;
}
.v-btn {
  color: #292937;
  font-family: Golos Text;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
}

.bible-transl {
  padding: 15px 20px;
  font-size: 16px;
  border-radius: 5px;
  line-height: 20px;
}

.bible-transl:hover {
  background: rgb(0 191 164 / 20%);
  cursor: pointer;
}
.bible-transl.active .strong-label {
  color: var(--root-white);
  border: solid 1.5px #fff;
}
.active {
  background: var(--root-green);
  color: var(--root-white);
  cursor: pointer;
}
.active:hover {
  background: var(--root-green);
  color: var(--root-white);
}
.bible-shortname {
  font-size: 14px;
  font-weight: 600;
}
.strong-label {
  color: var(--root-green);
  border: solid 1.5px var(--root-green);
  border-radius: 5px;
  font-size: 11px;
  font-weight: 600;
  padding: 0px 4px;
  line-height: 19px;
  margin-top: -2px;
}
</style>
